import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import LearningCard from '../components/ui/LearningCard'

export default function About() {

    useEffect(() => {
        document.title = "Michael Njogu | About Me"
    }, [])

        return (
           <div className="wrapper">

               <section className="content-section content-section-large section-intro">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="profile-img">
                                    <picture>
                                        <source srcSet='./images/profile/me-new.jpeg' media="(min-width:1024px)" />
                                        <img loading="lazy" src="./images/profile/me-new.jpg" alt="A handsome guy in a blue polo shirt crossing his arms" height="500" draggable="false" />
                                    </picture>
                                </div>
                            </Col>
                            <Col lg="8">
                                <div className="intro-content">
                                    <h1 className="page-title">Meet Michael.</h1>
                                    <p>I love to explore new materials, concepts, and techniques in any design I make. I have learned about UX/UI design and product design through online courses, workshops, reading blogs and applying the acquired knowledge in projects at work and during my free time.</p>
                                    <Link className="styled-link" to="/" title="View my work">Check out my work</Link>                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
               </section>

               <section className="content-section section-skills pb-0">
                   <div className="container">
                       <h2 className="section-title">Skills &amp; Tools</h2>
                       <div className="skills-container">
                           
                           <div className="skill-list">
                               <h3 className="skill-list-title">Design Skills</h3>
                               <ul>
                                   <li>Wireframing</li>
                                   <li>Visual Design</li>
                                   <li>Prototyping</li>
                                   <li>Usability Testing</li>
                                   <li>Frontend Design</li>
                                </ul>
                            </div>

                            <div className="skill-list">
                               <h3 className="skill-list-title">Tools</h3>
                               <ul>
                                   <li>Figma</li>
                                   <li>Adobe Photoshop &amp; Illustrator</li>
                                   <li>Google Analytics &amp; Looker Studio</li>
                                   <li>A pen and paper 😀</li>
                                </ul>
                            </div>      
                            
                            <div className="skill-list">
                               <h3 className="skill-list-title">Beyond Design</h3>
                               <ul>
                                   <li>HTML5</li>
                                   <li>CSS3</li>
                                   <li>Sass</li>
                                   <li>Bootstrap 5+</li>
                                   <li>WordPress</li>
                                   <li>Git</li>
                                </ul>
                            </div>                                                   

                       </div>
                   </div>
               </section>

                <section className="content-section section-exp pb-0">
                    <div className="container">
                        <h2 className="section-title">Work Experience</h2>
                        <div className="exp-container">

                            <div className="exp-item">
                                <h3>Senior UX/UI Designer, International Committee of the Red Cross (ICRC)</h3>
                                <p className="exp-period">November 2022 - present</p>
                                <span className="exp-location">Nairobi, Kenya</span>
                            </div>                            

                            <div className="exp-item">
                                <h3>UX/UI Designer, Open Institute</h3>
                                <p className="exp-period">June 2021 - November 2022</p>
                                <span className="exp-location">Nairobi, Kenya</span>
                            </div>

                            <div className="exp-item">
                                <h3>UX/UI Designer + Frontend Designer, Brainwave Communications Ltd.</h3>
                                <p className="exp-period">April 2016 - June 2021</p>
                                <span className="exp-location">Nairobi, Kenya</span>
                            </div>        
                            
                            <div className="exp-item">
                                <h3>Web Design Assistant + Graphic Designer, Harler Technologies</h3>
                                <p className="exp-period">January 2015 - July 2015</p>
                                <span className="exp-location">Nairobi, Kenya</span>
                            </div>                    

                        </div>
                    </div>
                </section>      

                <section className="content-section section-exp">
                    <div className="container">
                        <h2 className="section-title">Learning</h2>
                        <p>I'm a lifelong learner. I'm constantly reading, writing, and thinking. I learn from life and the world around me.</p>
                        <div className="exp-container">

                            <LearningCard
                                title="Certified SAFe&#174; 6 Agilist"
                                source="Scaled Agile, Inc."
                                link="https://www.interaction-design.org/members/michael-kunyiha-njogu/certificate/course/b776e417-27d1-4581-9ef2-ac7d131e7191"
                                icon="./images/profile/safe-6.png"
                                alt="IxDF logomark"
                            />

                            <LearningCard
                                title="Accessibility: How to Design for All"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.credly.com/badges/8d2ee9ca-8c5d-4ddc-9822-6f5d251bf2b8/linked_in_profile"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="SAFe 6 badge"
                            />                            
                            
                            <LearningCard
                                title="Data-Driven Design: Quantitative Research for UX"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.interaction-design.org/michael-kunyiha-njogu/certificate/course/b4021bd6-6d6f-4d77-9dbe-b8eedfa348ea"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="IxDF logomark"                                
                            />                            

                            <LearningCard
                                title="Journey Mapping"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.interaction-design.org/michael-kunyiha-njogu/certificate/course/613034c9-55a7-404d-9541-af4508c17538"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="IxDF logomark"                                
                            />

                            <LearningCard
                                title="Design for the 21st Century with Don Norman"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.interaction-design.org/michael-kunyiha-njogu/certificate/course/613034c9-55a7-404d-9541-af4508c17538"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="IxDF logomark"                                
                            />

                            <LearningCard
                                title="Human-Computer Interaction"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.interaction-design.org/michael-kunyiha-njogu/certificate/course/2c2e5b44-c2da-4afa-bf53-833ff982d7c4"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="IxDF logomark"                                
                            />                            
                            
                            <LearningCard
                                title="Become a UX Designer from Scratch"
                                source="The Interaction Design Foundation (IxDF)"
                                link="https://www.interaction-design.org/michael-kunyiha-njogu/certificate/course/cc1cd466-eb96-44bf-833e-148dd94b808f"
                                icon="./images/profile/IxDF-logomark.png"
                                alt="IxDF logomark"                                
                            />                            
                            
                            <LearningCard
                                title="Advanced Google Analytics"
                                source="Google"
                                link="https://analytics.google.com/analytics/academy/certificate/Fu7l472aS1yokkL248Ku-Q"
                                icon="./images/profile/google-logo.png"
                                alt="Google logo"                                
                            />                                                                     

                        </div>
                    </div>
                </section>                         


           </div>
        )
    }